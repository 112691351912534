import { jsonp, ajax } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from '@ifeng-fe/public_method';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */
if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

const md5 = require('md5');

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    while (window[jsonpCallbackName]) {
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

// 获取地方站要闻列表数据
export const getNewsDataList = async key => {
    // 必要！！！ 否则会引起地方站请求数据错乱
    const callback = createJsonpCallbackName('getNewsDataList');

    const data = await jsonp(`${apiBaseUrl}/index/newsData/${key}/${callback}`, {
        jsonpCallback: callback,
        cache: false,
    });

    if (data.code === 0) {
        return data.data;
    }

    return null;
};

/**
 * 获取凤首-风直播数据
 */
export const getFLiveData = async () => {
    const data = await jsonp('//console.zhibo.ifeng.com/web/api/getRecommendList', {
        jsonpCallback: `_${new Date().getTime()}`,
    });

    if (data && data.length) {
        return data.map(item => ({
            big_img: formatImage(item.thumb_new, 360, 203),
            small_img: formatImage(item.thumb_new, 175, 98),
            url: formatUrl(item.pc_url),
            title: item.title,
            status: item.status,
            status_txt: item.status_txt,
        }));
    }

    return [];
};

// 获取股票数据
export const getStockInfoData = async codeList => {
    const key = codeList.join(',');

    return await jsonp(`${apiBaseUrl}/index/stocData/${key}/getStockInfoData`, {
        jsonpCallback: 'getStockInfoData',
        cache: false,
    });
};

// 获取风财讯数据
export const getFengcxData = async () => {
    const data = await jsonp('https://fengcx.com/api/news/fapi/emotionCompanyList', {
        jsonpCallback: 'getFengcxData',
        cache: false,
    });

    if (data.data) {
        return {
            ...data.data,
            list: data.data.list.map(item => ({
                company_id: item.company_id,
                company_name: item.company_name,
                company_url: item.company_url,
                sentiment_url: item.sentiment_url,
                company_logo: formatImage(item.company_logo, 76, 76),
            })),
        };
    }

    return {};
};

// 获取最近阅读内容
export const getLastReadData = async (sid) => {
    const data = await jsonp(`https://androidapi4.yc.ifeng.com/api/xw/getReadHistory?sid=${sid}`, {
        jsonpCallback: 'getLastReadData',
        cache: false,
    });

    if (data?.data?.historyList?.[0]?.title && data?.code === 0) {
        return data.data.historyList[0];
    }

    return {};
};
