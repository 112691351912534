import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../../components/newsTitle';
import NewsList from '../../../../components/newsList';

class Area extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    render() {
        const {
            content: { animeAreaList, animeAreaTitle },
        } = this.props;

        return (
            <>
                <Chip id="260334" type="struct" title="其它地区动漫标题" groupName="动漫" content={animeAreaTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="286051"
                    type="recommend"
                    title="其它地区动漫列表"
                    groupName="动漫"
                    content={animeAreaList}
                    translate="handleNewsData">
                    <NewsList hasTitle size="short" />
                </Chip>
            </>
        );
    }
}

export default errorBoundary(Area);
