import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { cookie } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';

class SubNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        subNavArea: PropTypes.array,
    };

    state = {
        areaTitle: '',
        areaUrl: '',
    };

    componentDidMount() {
        const { subNavArea } = this.props;

        try {
            let areaTab = {};
            let areaTitle = '';
            let areaUrl = '';

            let prov = null;
            let city = null;

            if (typeof document !== 'undefined') {
                prov =
                    cookie.get('prov') && cookie.get('prov') !== '' && cookie.get('prov') !== 'cn010'
                        ? cookie.get('prov')
                        : null;
                city =
                    cookie.get('city') && cookie.get('city') !== '' && cookie.get('city') !== '010'
                        ? cookie.get('city')
                        : null;
            }

            if (subNavArea.length > 0 && prov && city) {
                const areaProvArr = subNavArea.filter(item => item.isShow).filter(item => item.prov === prov);
                const areaCityArr = areaProvArr.filter(item => item.city === city);

                if (areaCityArr.length > 0) {
                    areaTab = areaCityArr?.[0];
                    areaTitle = areaTab?.title;
                    areaUrl = areaTab?.url;
                } else {
                    areaTab = areaProvArr?.[0];
                    areaTitle = areaTab?.title;
                    areaUrl = areaTab?.url;
                }
            }

            if (typeof window !== 'undefined') {
                const path = window.location.href;

                // 地方站预览逻辑
                if (path.indexOf('visualediting') > -1 || path.indexOf('preview') > -1) {
                    const key = path.split('/')[path.split('/').length - 1];

                    const arr = subNavArea.filter(item => item.localKey === key);

                    if (arr.length > 0) {
                        areaTab = arr[0];
                        areaTitle = areaTab.title;
                        areaUrl = areaTab.url;
                    }
                }
            }

            this.setState({
                areaTab: { ...areaTab },
                areaTitle,
                areaUrl,
            });
        } catch (e) {
            console.error(e);
        }
    }

    getInsteadIndex = content => {
        let index = 15;

        try {
            for (let i = 0; i < content.length; i++) {
                if (content[i] && content[i].title === '家居') {
                    index = i;
                }
            }
        } catch (e) {
            console.error(e);

            return index;
        }

        return index;
    };

    /**
     * 渲染组件
     */
    render() {
        const { areaTitle, areaUrl } = this.state;
        const { content } = this.props;
        const len = content.length;
        const index = this.getInsteadIndex(content);

        if (areaTitle && areaUrl) {
            content.splice(index, 1, {
                title: areaTitle,
                url: areaUrl,
            });
        }

        return (
            <div id="subNav" className={styles.sub_box}>
                <ul className={styles.sub_nav}>
                    {content.map((item, index) => (
                        <li key={index} style={{ width: `${99 / len}%` }}>
                            <a href={item.url} target="_blank" rel={rel} title={item.title}>
                                {item.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(SubNav);
