import { jsonp } from '@ifeng-fe/ui_base';
import md5 from 'md5';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;
/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

// jsonp 接口请求示例 ---- start (开发时请删除示例代码)
// jsonp 接口请求示例 ---- start (开发时请删除示例代码)
// jsonp 接口请求示例 ---- start (开发时请删除示例代码)
const getWeatherData = async city => {
    return await jsonp(`https://api.iclient.ifeng.com/newH5Weather?key=weather&value=${city}`);
};

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    /* eslint-disable */
    while (typeof window === 'object' && window[jsonpCallbackName]) {
        /* eslint-enable */
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

const getMyStockData = async num => {
    return await jsonp('//apiapp.finance.ifeng.com/mystock/get', {
        data: { num },
        jsonpCallback: createJsonpCallbackName('getMyStockData'),
        cache: false,
    });
};

const sendError = (name, errorObject) => {
    const error = new Error(name);

    if (window && window.BJ_REPORT) {
        window.BJ_REPORT.report(errorObject || error, false, 43);
    }
};

// 获取节目单
const getPhoenixTvProgramList = async () => {
    let result = {};

    try {
        result = await jsonp('//nine.ifeng.com/phoenixTvProgramList?phtvType=phtvNews', {
            jsonpCallback: createJsonpCallbackName('getPhoenixTvProgramList'),
            cache: false,
        });

        if (!result || result.code !== 0) {
            sendError('getPhoenixTvProgramList获取节目单失败');
        }
    } catch (e) {
        sendError('getPhoenixTvProgramList获取节目单失败', e);
    }

    return result;
};

// 获取直播流回看
const getPhoenixTvDelay = async (id, day, seek) => {
    let result = {};

    try {
        result = await jsonp(`//nine.ifeng.com/PhoenixTvDelay?phtvType=phtvNews&index=${id}&seek=${seek}&day=${day}`, {
            jsonpCallback: createJsonpCallbackName('getPhoenixTvDelay'),
            cache: false,
        });

        if (!result || result.code !== 0) {
            sendError('getPhoenixTvDelay获取直播流回看失败');
        }
    } catch (e) {
        sendError('getPhoenixTvDelay获取直播流回看失败', e);
    }

    return result;
};

// 获取直播流
const getPhoenixtvlivedelay = async seek => {
    let result = {};

    try {
        result = await jsonp(`//nine.ifeng.com/phoenixtvlivedelay?phtvType=phtvNews&seek=${seek}`, {
            jsonpCallback: createJsonpCallbackName('getPhoenixtvlivedelay'),
            cache: false,
        });

        if (!result || result.code !== 0) {
            sendError('getPhoenixtvlivedelay获取直播流回看失败');
        }
    } catch (e) {
        sendError('getPhoenixtvlivedelay获取直播流回看失败', e);
    }

    return result;
};

// 获取单个行情数据
const getSingleMarket = async quoteCode => {
    const jsonpCallback = `callbck_quote_${quoteCode}`;
    const result = await jsonp(`https://shankapi.ifeng.com/finance/newest/quote/${quoteCode}/${jsonpCallback}`, {
        jsonpCallback,
        cache: false,
    });

    if (result?.data?.name) {
        return result.data;
    } else {
        return [];
    }
};

// 获取分时数据
const getStockTime = async quoteCode => {
    const jsonpCallback = `callbck_fenshi_${quoteCode}`;
    const result = await jsonp(`https://shankapi.ifeng.com/finance/fenshi/${quoteCode}/${jsonpCallback}`, {
        jsonpCallback,
        cache: false,
    });

    if (result?.data) {
        return result.data;
    } else {
        return {};
    }
};

// jsonp 接口请求示例 ---- end  (开发时请删除示例代码)
// jsonp 接口请求示例 ---- end  (开发时请删除示例代码)
// jsonp 接口请求示例 ---- end  (开发时请删除示例代码)

export {
    getMyStockData,
    getWeatherData,
    getPhoenixTvProgramList,
    getPhoenixTvDelay,
    getPhoenixtvlivedelay,
    getStockTime,
    getSingleMarket,
};
