import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import NoSSR from 'react-no-ssr';
import Ad from '@ifeng-fe/ui_pc_ad';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { autoTitle, autoList, shopInAuto, autoListAd, autoRSAd, replaceShopConfig2 },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="230184" type="struct" title="汽车标题" groupName="汽车" content={autoTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="288480"
                    type="recommend"
                    title="汽车栏新闻列表"
                    groupName="汽车栏"
                    content={autoList}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                {replaceShopConfig2.isShow ?
                    <ShopList size="long" ad={autoRSAd} content={shopInAuto} sourceName="shopInAuto" /> :
                    <ShopList size="long" ad={autoListAd} content={shopInAuto} sourceName="shopInAuto" />
                }
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
