import React from 'react';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import * as echarts from 'echarts';
import { getSingleMarket, getStockTime } from '@src/services/api';

class Stock extends React.PureComponent {
    state = {
        stockStartAndEndTime: {
            startTime: '',
            endTime: '',
        },
        singleMarket: {},
        stockTimeData: {},
    };

    timer1 = null;
    timer2 = null;

    canvasRef = React.createRef();
    creatArrayList = (allTimeDot, addDotNum) => {
        const dataList = allTimeDot.map(item => item?.tm || '');

        for (let i = 0; i < addDotNum; i++) {
            dataList.push('');
        }

        return dataList;
    };

    canvasHandle = (allTimeDot, echartsInfo) => {
        const echartsType = echartsInfo?.xAxis_type || 'time';
        const myChart = echarts.init(this.canvasRef.current);
        // 计算数据列表allTimeDot最小值和最大值
        const echartsData = allTimeDot.map(item => [item.tm, item.close]);
        const addDotNum = echartsInfo?.lackNum || 0;
        const timeEmptyList = this.creatArrayList(allTimeDot, addDotNum);
        const optionxAxisData = echartsType === 'time' ? echartsData : timeEmptyList;

        const option = {
            grid: {
                left: '0%',
                right: '0%',
                top: '0%',
                bottom: '15%',
            },
            xAxis: {
                type: echartsType,
                data: optionxAxisData,
                boundaryGap: false,
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#999999', // 设置 X 轴的颜色为红色
                    },
                },
                max: echartsInfo?.startAndEndTime?.fullEndTime,
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: false // 隐藏 Y 轴竖线
                },
                splitLine: {
                    show: false,
                },
                show: true,
                scale: true,
                splitNumber: 3,
                axisLabel: {
                    inside: true, // 刻度标签在网格区域内部显示
                    position: 'inside',
                    verticalAlign: 'bottom',
                    margin: 0,
                },
            },
            series: [
                {
                    type: 'line',
                    showSymbol: false,
                    smooth: true,
                    itemStyle: {
                        color: '#4a90e2', // 将数据点的颜色改为蓝色
                        color0: '#4a90e2', // 将数据点的颜色改为蓝色
                        borderColor: null,
                        borderColor0: null,
                      },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#608AE3', // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#ffffff', // 100% 处的颜色
                                },
                            ],
                            global: false, // 缺省为 false
                        },
                    },
                    data: echartsData,
                    lineStyle: {
                        color: '#608AE3',
                        width: 0.5,
                    },
                },
            ],
            tooltip: {
                trigger: 'axis',
            },
        };

        myChart.setOption(option);

        window.addEventListener('resize', () => {
            myChart.resize({
                width: this.canvasRef.current.clientWidth,
                height: this.canvasRef.current.clientHeight,
            });
        });
    };

    getMarketData = async () => {
        try {
            const quoteCode = 'SH0001';
            const singleMarketData = await getSingleMarket(quoteCode);

            this.setState({
                singleMarket: singleMarketData,
            });
        } catch (error) {
            console.error(error);
        }
    };

    getStockTimeData = async () => {
        try {
            const quoteCode = 'SH0001';
            const stockTimeData = await getStockTime(quoteCode);
            const allTimeDot = stockTimeData?.quoteTSDList || [];
            const echartInfo = stockTimeData?.echarts || {};

            this.setState({
                stockStartAndEndTime: {
                    startTime: echartInfo?.startAndEndTime?.startTime || '',
                    endTime: echartInfo?.startAndEndTime?.endTime || '',
                },
            });

            this.canvasHandle(allTimeDot, stockTimeData?.echarts);
        } catch (error) {
            console.error(error);
        }
    };

    async componentDidMount() {
        this.getMarketData();
        this.getStockTimeData();

        clearInterval(this.timer1);
        clearInterval(this.timer2);

        this.timer1 = setInterval(() => {
            this.getMarketData();
        }, 1000 * 10);

        this.timer2 = setInterval(() => {
            this.getStockTimeData();
        }, 1000 * 60);
    }

    componentWillUnmount() {
        clearInterval(this.timer1);
        clearInterval(this.timer2);
    }

    render() {
        const { singleMarket, stockStartAndEndTime } = this.state;

        let statusFlag = singleMarket?.range?.includes?.('-') ? 'down' : 'up';
        const statusClassList = {
            down: styles.stockGreenColor,
            up: styles.stockRedColor,
            flat: styles.stockBlackColor,
        };

        statusFlag = singleMarket?.range?.includes?.('--') ? 'flat' : statusFlag;

        return (
            <div className={styles.stockBox}>
                <div className={styles.stockCanvasBox}>
                    <div className={styles.stockCanvas} ref={this.canvasRef} />
                    <div className={styles.stockCanvasStartTime}>{stockStartAndEndTime?.startTime || ''}</div>
                    <div className={styles.stockCanvasEndTime}>{stockStartAndEndTime?.endTime || ''}</div>
                </div>
                <div className={styles.stockInfo}>
                    <div className={styles.stockInfoText}>{singleMarket?.name || ''}</div>
                    <div className={`${styles.stockInfoPrice} ${statusClassList[statusFlag]}`}>
                        {singleMarket?.tradePrice || ''}
                    </div>
                    <div className={`${styles.stockInfoRange} ${statusClassList[statusFlag]}`}>
                        <span className={styles.stockInfoRangeItem}>{singleMarket?.range || ''}</span>
                        <span className={styles.stockInfoRangeItem}>{singleMarket?.rangePercent || ''}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Stock);
