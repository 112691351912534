import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import NoSSR from 'react-no-ssr';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../../components/newsTitle';
import NewsList from '../../../../components/newsList';
import NewsBox from '../../../../components/newsBox';

class Fiction extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { fictionList, fictionBox, fictionTitle },
        } = this.props;

        return (
            <Fragment>
                <Chip id="200147" type="struct" title="北京河北地区小说标题" groupName="小说" content={fictionTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="285882"
                    type="recommend"
                    title="北京河北地区小说列表"
                    groupName="小说"
                    content={fictionList}
                    translate="handleNewsData">
                    <NewsList hasTitle size="long" />
                </Chip>
                <NoSSR>
                    <Chip
                        id="195877"
                        type="recommend"
                        title="北京河北地区小说新闻盒"
                        groupName="小说"
                        content={fictionBox}
                        translate="handleNewsBoxData">
                        <NewsBox />
                    </Chip>
                </NoSSR>
            </Fragment>
        );
    }
}

export default errorBoundary(Fiction);
