import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';

class FloatAds extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { floatAd1, floatAd2, floatAd3, floatAd4, floatAd5, floatAd6, floatAd7 },
        } = this.props;

        const floatAd = (
            <React.Fragment>
                {floatAd1 ? <Ad content={floatAd1} styleName={styles.floatAd} /> : ''}
                {floatAd2 ? <Ad content={floatAd2} styleName={styles.floatAd} /> : ''}
                {floatAd3 ? <Ad content={floatAd3} styleName={styles.floatAd} /> : ''}
                {floatAd4 ? <Ad content={floatAd4} styleName={styles.floatAd} /> : ''}
                {floatAd5 ? <Ad content={floatAd5} styleName={styles.floatAd} /> : ''}
                {floatAd6 ? <Ad content={floatAd6} styleName={styles.floatAd} /> : ''}
                {floatAd7 ? <Ad content={floatAd7} styleName={styles.floatAd} /> : ''}
            </React.Fragment>
        );

        return ReactDOM.createPortal(floatAd, document.body);
    }
}

export default errorBoundary(FloatAds);
