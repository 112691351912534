import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import { cookie } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import NewsBox from '../../../components/newsBox';
import NoSSR from 'react-no-ssr';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        city: null,
        localKey: null,
    };

    componentDidMount() {
        const {
            content: { houseAreaMap },
        } = this.props;

        let city = null;
        let localKey = null;

        try {
            if (typeof document !== 'undefined' && typeof window !== 'undefined') {
                city =
                    cookie.get('city') && cookie.get('city') !== '' && cookie.get('city') !== '010'
                        ? cookie.get('city')
                        : null;

                const houseArea = houseAreaMap.filter(item => item.city === city);

                if (houseArea.length > 0) {
                    localKey = houseArea[0].localKey;
                }

                const path = window.location.href;

                // 地方站预览逻辑
                if (path.indexOf('visualediting') > -1 || path.indexOf('preview') > -1) {
                    const key = path.split('/')[path.split('/').length - 1];

                    const arr = houseAreaMap.filter(item => item.localKey === key);

                    if (arr.length > 0) {
                        localKey = arr[0].localKey;
                    }
                }

                this.setState({
                    city,
                    localKey,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { city, localKey } = this.state;
        const {
            content: { houseTitle, houseList0, houseList1, houseList2, houseList3, areaList },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="200144" type="struct" title="房产标题" groupName="房产" content={houseTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                {city === '020' && localKey === 'guangzhou' ? (
                    <NewsList hasTitle size="long" content={areaList[`${localKey}HouseTitle`]} />
                ) : (
                    <Chip
                        id="225799"
                        type="recommend"
                        title="房产列表1"
                        groupName="房产"
                        content={houseList0}
                        translate="handleNewsData">
                        <NewsList hasTitle size="long" />
                    </Chip>
                )}
                {localKey ? (
                    <NewsList size="long" content={areaList[`${localKey}House`]} />
                ) : (
                    <Chip
                        id="195869"
                        type="recommend"
                        title="房产列表2"
                        groupName="房产"
                        content={houseList1}
                        translate="handleNewsData">
                        <NewsList size="long" />
                    </Chip>
                )}
                <Chip
                    id="255918"
                    type="recommend"
                    title="房产列表3"
                    groupName="房产"
                    content={houseList2}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                {localKey ? (
                    <NewsList size="long" content={areaList[`${localKey}HouseLast`]} />
                ) : (
                    <NoSSR>
                        <Chip
                            id="255917"
                            type="recommend"
                            title="房产列表4"
                            groupName="房产"
                            content={houseList3}
                            translate="handleNewsData">
                            <NewsBox />
                        </Chip>
                    </NoSSR>
                )}
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
