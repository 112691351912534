import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
// import FengcxBox from './fengcxBox';

class RightNewsBox extends React.PureComponent {    
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { fengcxTitle, fengcxList },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="230188" type="struct" title="风财讯标题" groupName="风财讯" content={fengcxTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <div className={styles.list_box}>
                    <Chip
                        id="288413"
                        type="recommend"
                        title="风财讯列表"
                        groupName="风财讯"
                        content={fengcxList}
                        translate="handleNewsData">
                        <NewsList size="short" hasTitleImg />
                    </Chip>
                </div>
                {/* <FengcxBox /> */}
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
