import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NoSSR from 'react-no-ssr';
import Slides from '@ifeng-fe/ui_pc_slides';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import { rel } from '../../../../../utils/rel';

import styles from './index.css';

class FLive extends React.PureComponent {
    static propTypes = {
        ad: PropTypes.object,
        slideData: PropTypes.array,
    };

    state = {
        slideData: [...this.props.slideData],
    };

    event = new Event();

    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { slideData } = this.state;
            const infoData = [...slideData];
            const refs = [];

            // 业务原因，限制此处广告只能替换

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(mainData => {
                const ref = React.createRef();

                refs.push({ index: mainData, ref });

                infoData[mainData] = {
                    ...infoData[mainData],
                    subData: { type: 'ad', ref },
                };
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    slideData: [...infoData],
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        // 在ipad下面，广告不会注入，需要判断一下是否有广告
        const { ad } = this.props;

        if (typeof ad === 'undefined') {
            return;
        }

        try {
            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert);
        } catch (e) {
            console.error(e);
        }

        try {
            this.event.trigger('init');
        } catch (e) {
            console.error(e);
        }
    }

    sliderTmpl = data => {
        const { mainData, subData } = data;

        return (
            <div className={styles.slide_box}>
                <div className={styles.main_box}>
                    <a href={mainData.url} target="_blank" title={mainData.title} rel={rel} data-nomask>
                        <img src={formatImageWebpAuto(mainData.big_img, 360, 203)} alt={mainData.title} />
                        <span className={mainData.status === 3 ? styles.nolive : styles.live}>
                            {mainData.status_txt}
                        </span>
                    </a>
                    <p className={styles.title}>
                        <a href={mainData.url} target="_blank" title={mainData.title} rel={rel} data-innermask>
                            {mainData.title}
                        </a>
                    </p>
                </div>
                {subData.type === 'ad' ? (
                    <div className={styles.sub_box} ref={subData.ref} />
                ) : (
                    <div className={styles.sub_box}>
                        {subData.map((item, index) => (
                            <div className={`${styles.sub} ${index === 0 ? styles.sub_l : styles.sub_r}`} key={index}>
                                <a href={item.url} target="_blank" title={item.title} rel={rel} data-nomask>
                                    <img src={formatImageWebpAuto(item.small_img, 175, 98)} alt={mainData.title} />
                                </a>
                                <p className={styles.sub_title}>
                                    <a href={item.url} target="_blank" title={item.title} rel={rel} data-innermask>
                                        {item.title}
                                    </a>
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { slideData } = this.state;

        const sliderConfig = {
            arrows: false,
            dots: true,
            dotsAction: 'hover',
            autoplayInterval: 5000,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl: this.sliderTmpl,
        };

        return (
            <div className={styles.flive_box}>
                <NoSSR>
                    <Slides content={slideData} config={sliderConfig} />
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(FLive);
