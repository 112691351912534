import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import Promote from '../../../components/promote';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { gongyiTitle, gongyiList, gongyiHardAd, promoteInGongyi },
        } = this.props;

        return (
            <div className={styles.left_box}>
                <Chip id="230189" type="struct" title="公益标题" groupName="公益" content={gongyiTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="195876"
                    type="recommend"
                    title="公益列表"
                    groupName="公益"
                    content={gongyiList}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                <NoSSR>
                    <Ad content={gongyiHardAd} styleName={styles.dbox} />
                </NoSSR>
                {promoteInGongyi && (
                    <Chip id="230197" type="struct" title="铺底推广" groupName="公益" content={promoteInGongyi}>
                        <Promote />
                    </Chip>
                )}
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
