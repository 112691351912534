import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../utils/rel';

class Promote extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { url, img },
        } = this.props;

        return (
            <div className={styles.promote_box}>
                <a href={url} target="_target" rel={rel}>
                    <img src={img} alt="" />
                </a>
            </div>
        );
    }
}

export default errorBoundary(Promote);
