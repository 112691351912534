import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';
import Search from '@ifeng-fe/ui_pc_search';
import Logo from './logo';
import Jubao from './jubao';
import Donate from './donate';
import { rel } from '../../../utils/rel';

/**
 * 定义 Header 组件
 */
class Header extends React.PureComponent {
    static propTypes = {
        IE78: PropTypes.bool,
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            IE78,
            content: { logo, danate, search, jubaoData },
        } = this.props;

        const SearchComp = errorBoundary(Search);

        return (
            <div id="main_nav" className={styles.header}>
                <div className={styles.header_l}>
                    <Chip id="50055" type="struct" title="logo" groupName="顶部" content={logo}>
                        <Logo />
                    </Chip>
                </div>
                <div className={styles.header_r}>
                    <div className={styles.box_top}>
                        <div className={styles.donate_box}>
                            <Chip id="230180" type="struct" title="凤凰公益" groupName="顶部" content={danate}>
                                <Donate />
                            </Chip>
                        </div>
                        <div className={styles.jb_box}>
                            <Chip id="170183" type="struct" title="举报信息" groupName="顶部" content={jubaoData}>
                                <Jubao />
                            </Chip>
                        </div>
                    </div>
                    <div className={styles.login}>
                        <div className={styles.search}>
                            <Chip id="20005" type="struct" title="搜索" groupName="顶部" content={search}>
                                <SearchComp expand isIE78={IE78} />
                            </Chip>
                        </div>
                        <div className={IE78 ? styles.phtv : styles.phtv_2x}>
                            <span />
                            <a
                                href="https://www.phoenixtv.com"
                                target="_blank"
                                rel={rel}
                                sign-trs-href="https://www.phoenixtv.com">
                                PHOENIX TV
                            </a>
                        </div>
                        <div className={IE78 ? styles.ifeng : styles.ifeng_2x}>
                            <span />
                            <a href="//a.ifeng.com/?from=home" target="_blank" rel={rel}>
                                下载客户端
                            </a>
                        </div>
                        <div className={styles.user}>
                            <NoSSR>
                                <UserInfo iconShow option={['register', 'login']} isIE78={IE78} />
                            </NoSSR>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(Header);
