import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import LeftNewsBox from './leftNewsBox';
import CenterNewsBox from './centerNewsBox';
import RightNewsBox from './rightNewsBox';

class NewsBox0 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { leftData, centerData, rightData },
        } = this.props;

        return (
            <div className={styles.news_box}>
                <LeftNewsBox content={leftData} />
                <CenterNewsBox content={centerData} />
                <RightNewsBox content={rightData} />
            </div>
        );
    }
}

export default errorBoundary(NewsBox0);
