import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import Ad from '@ifeng-fe/ui_pc_ad';
import NoSSR from 'react-no-ssr';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { fashionTitle, fashionList0, shopInFashion, fashionList1, fashtionAd, fashionRSAd, replaceShopConfig },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="200142" type="struct" title="时尚标题" groupName="时尚" content={fashionTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="225753"
                    type="recommend"
                    title="时尚列表1"
                    groupName="时尚"
                    content={fashionList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                {replaceShopConfig.isShow ?
                    <NoSSR>
                        <Ad content={fashionRSAd} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="long" content={shopInFashion} sourceName="shopInFashion" />
                }
                <Chip
                    id="285880"
                    type="recommend"
                    title="时尚列表2"
                    groupName="时尚"
                    content={fashionList1}
                    translate="handleNewsData">
                    <NewsList size="long" ad={fashtionAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
