import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { cookie } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Fiction from './fiction';
import FictionExtra from './fictionExtra';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        prov: null,
    };

    componentDidMount() {
        let prov = null;

        try {
            if (typeof document !== 'undefined') {
                prov = cookie.get('prov') && cookie.get('prov') !== '' ? cookie.get('prov') : null;
            }

            if (typeof window !== 'undefined') {
                const path = window.location.href;

                // 地方站预览逻辑
                if (
                    (path.indexOf('visualediting') > -1 || path.indexOf('preview') > -1) &&
                    path.indexOf('extra') > -1
                ) {
                    prov = null;
                }
            }

            this.setState({
                prov,
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { prov } = this.state;
        const {
            content: { fictionTitle, fictionList, fictionBox, fictionAreaList, fictionAreaBox, fictionAreaTitle },
        } = this.props;

        const unExtra = prov === 'cn010' || prov === 'cn0311';

        return (
            <div className={styles.center_box}>
                {unExtra ? (
                    <Fiction
                        content={{
                            fictionTitle,
                            fictionList,
                            fictionBox,
                        }}
                    />
                ) : (
                    <FictionExtra
                        content={{
                            fictionAreaTitle,
                            fictionAreaList,
                            fictionAreaBox,
                        }}
                    />
                )}
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
