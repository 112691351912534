import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { Event } from '@ifeng-fe/ui_base';
import { handleAd } from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NoSSR from 'react-no-ssr';
import ListTitle from './listTitle';
import TitleImg from './titleImg';
import { isIE78 } from '../../../utils/utils';
import { rel } from '../../../utils/rel';
import { shopDataInit, shopDataHandlePlan1, shopDataHandlePlan2 } from '@src/pc/utils/shop';

const formatList = (list, ads) => {
    for (const ad of ads) {
        if (ad.type === 'replaceArr') {
            list.splice(ad.index, 1, { type: 'ad', ref: ad.ref });
        } else if (ad.type === 'insertArr') {
            list.splice(ad.index, 0, { type: 'ad', ref: ad.ref });
        }
    }

    return list;
};

class ShopList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        hasTitle: PropTypes.bool,
        hasTitleImg: PropTypes.bool,
        isVideoList: PropTypes.bool,
        isBig: PropTypes.bool,
        isFirstBold: PropTypes.bool,
        size: PropTypes.string,
        ad: PropTypes.object,
        yellowIcon: PropTypes.bool,
        isUnfold: PropTypes.bool,
        from: PropTypes.string,
        noChildren: PropTypes.bool,
        sourceName: PropTypes.string,
    };

    state = {
        ads: [],
        shopData: shopDataInit(this.props.content)
    };

    event = new Event();

    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const refs = [];

            replaceArr.forEach(index => {
                const ref = React.createRef();

                refs.push({ index, ref, type: 'replaceArr' });
            });

            insertArr.forEach(index => {
                const ref = React.createRef();

                refs.push({ index, ref, type: 'insertArr' });
            });

            this.setState(
                {
                    ads: [...this.state.ads, ...refs],
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        if (ref?.ref?.current) {
                            dom[ref.index] = ref.ref.current;
                        }
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    shopDataTransform = () => {
        if (this.props.sourceName) {
            this.setState({
                shopData: shopDataHandlePlan2(this.props.content, this.props.sourceName)
            });
        } else {
            this.setState({
                shopData: shopDataHandlePlan1(this.props.content)
            });
        }
    }

    changeShopData = () => {
        if (this.props.sourceName) {
            // 避免点击后数据马上发生变化 导致跳转链接异常
            
            setTimeout(() => {
                this.setState({
                    shopData: shopDataHandlePlan2(this.props.content, this.props.sourceName)
                });
            }, 300);
        }
    }

    async componentDidMount() {
        const { ad } = this.props;

        this.shopDataTransform();
        if (ad) {
            try {
                const callback = await handleAd(ad);

                callback(ad.data, this.event, this.insert);
            } catch (e) {
                console.error(e);
            }

            try {
                this.event.trigger('init');
            } catch (e) {
                console.error(e);
            }
        }
    }

    handleListStyle = pcNewsMark => {
        const { isVideoList } = this.props;

        const maskStyle = [];
        let isVideo = false;
        let hasZoujc = false;
        let hasAd = false;

        if (pcNewsMark && pcNewsMark.length > 0) {
            if (pcNewsMark.indexOf(1) > -1) {
                maskStyle.push(styles.bold);
            }

            if (pcNewsMark.indexOf(2) > -1) {
                isVideo = true;
            }

            if (pcNewsMark.indexOf(3) > -1) {
                hasZoujc = true;
            }

            if (pcNewsMark.indexOf(4) > -1) {
                maskStyle.push(styles.cnRed);
            }

            if (pcNewsMark.indexOf(5) > -1) {
                hasAd = true;
            }
        }

        isVideo = isVideo || isVideoList;

        return {
            isVideo,
            hasZoujc,
            hasAd,
            maskStyleName: maskStyle.join(' '),
        };
    };

    randFilterHandle = (dataArray = []) => {
        if (!dataArray || dataArray.length === 0) {
            return [];
        }

        const result = dataArray.map(item => {
            // 是否随机显示一条数据
            if (item.marquee && item.marquee === 1) {
                let result = [item];
                let num = 0;

                if (item.children && item.children.length > 0) {
                    result = result.concat(item.children);
                    delete result?.[0]?.children;
                    num = Math.floor(Math.random() * result.length);
                }

                return result[num];
            } else {
                return item;
            }
        });

        if (result && result.length > 0) {
            return result;
        } else {
            return [];
        }
    };

    render() {
        const { ads, shopData } = this.state;
        const {
            hasTitle,
            hasTitleImg,
            isBig,
            size,
            isFirstBold,
            yellowIcon,
            isUnfold,
            from,
            noChildren,
        } = this.props;
        let titleData = [];
        let data = [...shopData];

        data = formatList(data, ads);

        if (hasTitle || hasTitleImg) {
            titleData = data.slice(0, 1);
            data = data.slice(1);
            titleData = this.randFilterHandle(titleData);
            data = this.randFilterHandle(data);
        }

        /* eslint-disable no-confusing-arrow */
        return (
            <div className={`${styles.news_list} ${isUnfold ? styles.news_list_unfold : ''}`} onClick={this.changeShopData}>
                <NoSSR>{hasTitleImg ? <TitleImg size={size} content={titleData[0]} /> : ''}</NoSSR>
                {hasTitle ? <ListTitle isBig={isBig} isUnfold content={titleData} /> : ''}
                {data.map((item, index) => {
                    let isBold = false;

                    if (item.type === 'ad') {
                        return <li key={index} ref={item.ref} />;
                    }
                    if ((isFirstBold && index === 0) || (from === 'headlines' && index === 0)) {
                        isBold = true;
                    }

                    const { isVideo, hasZoujc, maskStyleName, hasAd } = this.handleListStyle(item.pcNewsMark);

                    return (
                        <p key={index} className={`${styles.news_list_p} ${hasAd ? styles.news_list_markTag : ''}`}>
                            {hasZoujc ? <span className={styles.zoujc} /> : ''}
                            {isVideo && !yellowIcon ? (
                                <span className={isIE78() ? styles.video : styles.video_2x} />
                            ) : (
                                ''
                            )}
                            {isVideo && yellowIcon ? (
                                <span className={isIE78() ? styles.videoYellow : styles.video_2xYellow} />
                            ) : (
                                ''
                            )}
                            {item.marquee !== 1 && item.children && item.children.length > 0 ? (
                                <React.Fragment>
                                    <a
                                        className={`${maskStyleName} ${styles.space} ${isBold ? styles.bold : ''}`}
                                        title={item.title}
                                        href={item.url}
                                        target="_blank"
                                        rel={rel}>
                                        {item.title}
                                    </a>
                                    {item.children.map((child, ind) => {
                                        const { maskStyleName } = this.handleListStyle(child.pcNewsMark);

                                        return (
                                            <a
                                                key={ind}
                                                className={`${maskStyleName} ${styles.space} ${
                                                    isBold ? styles.bold : ''
                                                }`}
                                                title={item.title}
                                                href={child.url}
                                                target="_blank"
                                                rel={rel}>
                                                {child.title}
                                            </a>
                                        );
                                    })}
                                </React.Fragment>
                            ) : (
                                <a
                                    className={`${maskStyleName} ${isBold ? styles.bold : ''}`}
                                    title={item.title}
                                    href={item.url}
                                    target="_blank"
                                    rel={rel}>
                                    {item.title}
                                </a>
                            )}
                            {hasAd && (
                                <img
                                    className={`${styles.markTag} ${from !== 'focusNews' ? 'hideMark' : ''} `}
                                    src="https://x0.ifengimg.com/feprod/c/2023_1_17/16_21_21/1.png"
                                />
                            )}
                        </p>
                    );
                })}
            </div>
        );
        /* eslint-enable no-confusing-arrow */
    }
}

export default errorBoundary(ShopList);
