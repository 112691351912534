import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import { rel } from '../../../utils/rel';
import { shopDataInit, shopDataHandlePlan1, shopDataHandlePlan2 } from '@src/pc/utils/shop';

class ShopBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        sourceName: PropTypes.string
    };

    state = {
        shopData: shopDataInit(this.props.content)
    }

    shopDataTransform = () => {
        if (this.props.sourceName) {
            this.setState({
                shopData: shopDataHandlePlan2(this.props.content, this.props.sourceName)
            });
        } else {
            this.setState({
                shopData: shopDataHandlePlan1(this.props.content)
            });
        }
    }

    changeShopData = () => {
        if (this.props.sourceName) {
            // 避免点击后数据马上发生变化 导致跳转链接异常
            
            setTimeout(() => {
                this.setState({
                    shopData: shopDataHandlePlan2(this.props.content, this.props.sourceName)
                });
            }, 300);
        }
    }

    componentDidMount() {
        this.shopDataTransform();
    }

    render() {
        const { shopData } = this.state;

        return (
            <div className={styles.news_box}>
                {shopData.map((item, index) => (
                    <a key={index} className={styles.news} href={item.url} target="_blank" rel={rel} onClick={this.changeShopData}>
                        <img src={formatImageWebpAuto(item.thumbnail, 230, 130)} alt="" />
                        <p className={styles.text}>
                            <span>{item.title}</span>
                        </p>
                    </a>
                ))}
            </div>
        );
    }
}

export default errorBoundary(ShopBox);
