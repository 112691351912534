import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../../components/newsTitle';
import NewsList from '../../../../components/newsList';

class Normal extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { animeTitle, animeList },
        } = this.props;

        return (
            <>
                <Chip id="200149" type="struct" title="北京河北地区动漫标题" groupName="动漫" content={animeTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="285884"
                    type="recommend"
                    title="北京河北地区动漫列表"
                    groupName="动漫"
                    content={animeList}
                    translate="handleNewsData">
                    <NewsList hasTitle size="short" />
                </Chip>
            </>
        );
    }
}

export default errorBoundary(Normal);
