import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ModuleTitle from './moduleTitle';
import NewsList from '../../../components/newsList';
import PhtvSlide from './phtvSlide';
import SubTitle from './subTitle';
import PhtvPeople from './phtvPeople';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: {
                phtvTitle,
                phtvSlide,
                phtvVideoInfo,
                phtvNewProgramTitle,
                phtvNewProgramList,
                phtvPlaybackTitle,
                phtvPlaybackList,
                phtvPeopleTitle,
                phtvPeopleNewList,
                phtvPeopleNameList,
                phtvAd,
            },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="260116" type="struct" title="凤凰卫视标题" groupName="视频区" content={phtvTitle}>
                    <ModuleTitle size="short" />
                </Chip>
                <Chip id="285787" type="recommend" title="凤凰卫视焦点图" groupName="视频区" content={phtvSlide}>
                    <PhtvSlide />
                </Chip>
                <Chip
                    id="260117"
                    type="struct"
                    title="凤凰卫视最新节目标题"
                    groupName="视频区"
                    content={phtvNewProgramTitle}>
                    <SubTitle />
                </Chip>
                <Chip
                    id="285560"
                    type="recommend"
                    title="凤凰卫视最新节目列表"
                    groupName="视频区"
                    content={phtvNewProgramList}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                <Chip
                    id="260118"
                    type="struct"
                    title="凤凰卫视精彩回放标题"
                    groupName="视频区"
                    content={phtvPlaybackTitle}>
                    <SubTitle />
                </Chip>
                <Chip
                    id="285561"
                    type="recommend"
                    title="凤凰卫视精彩回放列表"
                    groupName="视频区"
                    content={phtvPlaybackList}
                    translate="handleNewsData">
                    <NewsList isVideoList yellowIcon size="short" ad={phtvAd} />
                </Chip>
                <Chip id="260119" type="struct" title="凤凰卫视凤凰人标题" groupName="视频区" content={phtvPeopleTitle}>
                    <SubTitle />
                </Chip>
                <Chip
                    id="285562"
                    type="recommend"
                    title="凤凰卫视凤凰人列表"
                    groupName="视频区"
                    content={phtvPeopleNewList}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                <Chip
                    id="260120"
                    type="struct"
                    title="凤凰卫视凤凰人名字"
                    groupName="视频区"
                    content={phtvPeopleNameList}>
                    <PhtvPeople />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
