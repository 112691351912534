import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { isIE } from '../../../utils/utils';

class LowBrower extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        IE78: PropTypes.bool,
    };

    state = {
        isShow: false,
    };

    componentDidMount() {
        const { IE78 } = this.props;

        console.log('IE78 =====', IE78);

        const isIE678 = isIE(6) || isIE(7) || isIE(8);

        console.log('isIE678 =====', isIE678);

        if (IE78 && isIE678) {
            this.setState({
                isShow: true,
            });
        }
    }

    handleClick = () => {
        this.setState(
            {
                isShow: false,
            },
            () => {
                const error = new Error('lowBrowerClose');

                if (window && window.BJ_REPORT) window.BJ_REPORT.report(error, false, 24);
            },
        );
    };

    render() {
        const { isShow } = this.state;
        const { content } = this.props;

        const lowBrower = (
            <div className={isShow ? styles.show : styles.hide}>
                <div className={styles.lowBrower} />
                <div className={styles.col_box}>
                    <div className={styles.col}>
                        <a className={styles.close} onClick={this.handleClick}>
                            关闭
                        </a>
                        <div className={styles.ifengLogo}>
                            <a href="//www.ifeng.com" title="凤凰网">
                                <span />
                            </a>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.tip_content}`}>
                        <div className={styles.tips}>
                            <h2>亲爱的凤凰网用户:</h2>
                            <p>您当前使用的浏览器版本过低，导致网站不能正常访问，建议升级浏览器</p>
                            <h2>第三方浏览器推荐:</h2>
                            <p className={styles.OB_line}>
                                <img
                                    className={styles.otherBrowser_logo}
                                    src="//p1.ifengimg.com/a/2018/0920/chrome.jpg"
                                />
                                <span>
                                    谷歌(Chrome)浏览器<a href="https://www.google.com/chrome/" target="_blank">
                                        下载
                                    </a>
                                </span>
                            </p>
                            <p className={styles.OB_line}>
                                <img
                                    className={styles.otherBrowser_logo}
                                    src="//p1.ifengimg.com/a/2018/0920/360_logo.png"
                                />
                                <span>
                                    360安全浏览器<a
                                        href="https://browser.360.cn/se/"
                                        target="_blank"
                                        rel="nofollow me noopener noreferrer">
                                        下载
                                    </a>
                                </span>
                            </p>
                        </div>
                        <div className={styles.downApp}>
                            <img src={content.qrCode} />
                        </div>
                    </div>
                </div>
            </div>
        );

        return ReactDOM.createPortal(lowBrower, document.body);
    }
}

export default errorBoundary(LowBrower);
