import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';

class Nav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const len = content.length;

        return (
            <div className={styles.navigation_box}>
                <ul id="nav" className={styles.navigation}>
                    {content.map((item, index) => (
                        <li key={index} style={{ width: `${99 / len}%` }}>
                            <a href={item.url} target="_blank" rel={rel} title={item.title}>
                                {item.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(Nav);
