import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Footer extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
    };

    render() {
        const { content } = this.props;

        return <div className={styles.footer} dangerouslySetInnerHTML={{ __html: content }} />;
    }
}

export default errorBoundary(Footer);
