import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import Stock from './stock';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { quotationTitle, quotationImg, quotationSearch, quotationList, quotationListAd },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="170173" type="struct" title="行情标题" groupName="行情" content={quotationTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <NoSSR>
                    <Stock />
                </NoSSR>
                <Chip
                    id="255865"
                    type="recommend"
                    title="行情列表"
                    groupName="行情"
                    content={quotationList}
                    translate="handleNewsData">
                    <NewsList size="short" ad={quotationListAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
