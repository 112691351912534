import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import NoSSR from 'react-no-ssr';
import Ad from '@ifeng-fe/ui_pc_ad';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { jiuTitle, shopRegionList1, shopRegionList2, shopRegionList3, shopRegionList4, jiuList, shopAd, jiuARSAd, jiuBRSAd, replaceShopConfig2 },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="200143" type="struct" title="电商酒业标题" groupName="电商酒业" content={jiuTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="285492"
                    type="recommend"
                    title="电商列表1"
                    groupName="电商酒业"
                    content={shopRegionList1}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="short" />
                </Chip>
                {replaceShopConfig2.isShow ?
                    <NoSSR>
                        <Ad content={jiuARSAd} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="short" content={shopRegionList2} sourceName="shopRegionList2" />
                }
                <Chip
                    id="285494"
                    type="recommend"
                    title="电商列表3"
                    groupName="电商酒业"
                    content={shopRegionList3}
                    translate="handleNewsData">
                    <NewsList size="short" />
                </Chip>
                <Chip
                    id="225756"
                    type="recommend"
                    title="酒业列表"
                    groupName="酒业"
                    content={jiuList}
                    translate="handleNewsData">
                    <NewsList size="short" />
                </Chip>
                {replaceShopConfig2.isShow ?
                    <ShopList size="short" ad={jiuBRSAd} content={shopRegionList4} sourceName="shopRegionList4" /> :
                    <ShopList size="short" ad={shopAd} content={shopRegionList4} sourceName="shopRegionList4" />
                }
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
