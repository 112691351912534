import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class PhtvPeople extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return content && content.length > 0 ? (
            <p className={styles.phtvPeopleBox}>
                {content.slice(0, 6).map((item, index) => {
                    return (
                        <a
                            key={index}
                            className={index !== content.length - 1 ? styles.phtvPeopleLink : styles.phtvPeopleLastLink}
                            href={item.url}
                            target="_blank">
                            {item.title}
                        </a>
                    );
                })}
            </p>
        ) : (
            ''
        );
    }
}

export default errorBoundary(PhtvPeople);
