import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';

class Logo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { url, title, src },
        } = this.props;

        return (
            <div className={styles.logo}>
                <a href={url} target="_blank" rel={rel} title={title}>
                    <img src={src} alt={title} title={title} />
                </a>
            </div>
        );
    }
}

export default errorBoundary(Logo);
