import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import Promote from '../../../components/promote';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: {
                milTitle,
                milList0,
                shopInMil,
                milList1,
                cultureBookListAd,
                cultureBookHardAd,
                promoteInCultureBook,
                milRSAd,
                replaceShopConfig2
            },
        } = this.props;

        return (
            <div className={styles.left_box}>
                <Chip id="230181" type="struct" title="军事标题" groupName="军事" content={milTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="195854"
                    type="recommend"
                    title="军事列表1"
                    groupName="军事"
                    content={milList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                {replaceShopConfig2.isShow ?
                    <NoSSR>
                        <Ad content={milRSAd} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="long" content={shopInMil} sourceName="shopInMil" />
                }
                <Chip
                    id="195862"
                    type="recommend"
                    title="军事列表2"
                    groupName="军事"
                    content={milList1}
                    translate="handleNewsData">
                    <NewsList size="long" ad={cultureBookListAd} />
                </Chip>
                <NoSSR>
                    <Ad content={cultureBookHardAd} styleName={styles.dbox} />
                </NoSSR>
                {promoteInCultureBook && (
                    <Chip
                        id="230193"
                        type="struct"
                        title="铺底推广"
                        groupName="文化读书"
                        content={promoteInCultureBook}>
                        <Promote />
                    </Chip>
                )}
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
