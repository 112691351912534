import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { digitalTitle, digitalList, historyListAd },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="170178" type="struct" title="数码评测标题" groupName="数码评测" content={digitalTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="255871"
                    type="recommend"
                    title="数码评测列表"
                    groupName="数码评测"
                    content={digitalList}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="short" ad={historyListAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
