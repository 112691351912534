import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../utils/rel';

class NewsTitle extends React.PureComponent {
    static propTypes = {
        size: PropTypes.string,
        type: PropTypes.string,
        current: PropTypes.number,
        content: PropTypes.array,
        children: PropTypes.array,
    };

    state = {
        current: this.props.current,
    };

    handleMouseOver = e => {
        const index = Number(e.currentTarget.getAttribute('index'));

        if (index !== this.state.current) {
            this.setState({
                current: index,
            });
        }
    };

    render() {
        const { current } = this.state;
        const { size, type, content } = this.props;

        const titleArr = content.filter(item => !item.moreImg);
        const moreArr = content.filter(item => item.moreImg);

        let styleName = '';

        if (size === 'longer') {
            styleName = styles.longer_title_box;
        } else if (size === 'long') {
            styleName = styles.long_title_box;
        } else if (size === 'middle') {
            styleName = styles.middle_title_box;
        } else if (size === 'short') {
            styleName = styles.short_title_box;
        }
        const isAdImg0 = moreArr?.[0]?.moreImg === 'https://x0.ifengimg.com/feprod/c/2023_1_17/16_21_21/1.png';
        const isAdImg1 = moreArr?.[1]?.moreImg === 'https://x0.ifengimg.com/feprod/c/2023_1_17/16_21_21/1.png';

        return (
            <Fragment>
                <div className={`${styles.title_box} ${styleName}`}>
                    {type === 'tab' ? (
                        <Chip id="200153" type="struct" title="要闻标题" groupName="要闻区">
                            <div className={styles.title}>
                                {titleArr.map((item, index) => (
                                    <Fragment key={index}>
                                        {index === 0 ? '' : <span className={styles.dr} />}
                                        <a
                                            className={`${current === index ? '' : styles.no_active} ${
                                                index === 0 ? '' : styles.tab
                                            }`}
                                            href={item.url}
                                            target="_blank"
                                            rel={rel}
                                            index={index}
                                            onMouseOver={this.handleMouseOver}>
                                            {item.title}
                                        </a>
                                    </Fragment>
                                ))}
                            </div>
                        </Chip>
                    ) : (
                        <div className={styles.title}>
                            {titleArr.map((item, index) => (
                                <Fragment key={index}>
                                    {index === 0 ? '' : <span>·</span>}
                                    <a href={item.url} target="_blank" rel={rel}>
                                        {item.title}
                                    </a>
                                </Fragment>
                            ))}
                        </div>
                    )}
                    {moreArr.length > 0 && (
                        <a
                            className={styles.more}
                            style={{ bottom: `${moreArr[0].moreBottom || 0}px` }}
                            href={moreArr[0].moreUrl}
                            target="_blank"
                            rel={rel}>
                            <img
                                src={moreArr[0].moreImg}
                                className={`${isAdImg0 ? 'hideMark' : ''}`}
                                width={moreArr[0].moreImgWidth}
                                height={moreArr[0].moreImgHeight}
                            />
                            {moreArr?.[1]?.moreImg && (
                                <img
                                    className={`${styles.mark} ${isAdImg1 ? 'hideMark' : ''}`}
                                    src={moreArr[1].moreImg}
                                    width={moreArr[1].moreImgWidth}
                                    height={moreArr[1].moreImgHeight}
                                />
                            )}
                        </a>
                    )}
                </div>
                {type === 'tab' &&
                    React.Children.map(this.props.children, (item, index) =>
                        React.cloneElement(item, { active: current === index }),
                    )}
            </Fragment>
        );
    }
}

export default errorBoundary(NewsTitle);
