import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class SubTitle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            content &&
            content[0] && (
                <div className={styles.subTitle}>
                    <a className={styles.subTitleLink} href={content[0].url} target="_blank">
                        {content[0].title}
                        <img
                            className={styles.subTitleLine}
                            src="https://x0.ifengimg.com/ucms/2021_51/1B231E86976F1E8BC0E1C0BAB2ECC39AED93073A_size0_w8_h34.png"
                        />
                    </a>
                </div>
            )
        );
    }
}

export default errorBoundary(SubTitle);
