import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { fengTitle, mediaTitle, mediaList, coorTitle, coorList },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="170181" type="struct" title="关注凤凰标题" groupName="关注凤凰" content={fengTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <div className={styles.list_box}>
                    <Chip
                        id="195907"
                        type="recommend"
                        title="关于凤凰新媒体标题"
                        groupName="关注凤凰"
                        content={mediaTitle}
                        translate="handleNewsData">
                        <NewsList size="short" />
                    </Chip>
                    <Chip
                        id="225767"
                        type="recommend"
                        title="关于凤凰新媒体列表"
                        groupName="关注凤凰"
                        content={mediaList}
                        translate="handleNewsData">
                        <NewsList size="short" />
                    </Chip>
                    <Chip
                        id="195908"
                        type="recommend"
                        title="媒体合作标题"
                        groupName="关注凤凰"
                        content={coorTitle}
                        translate="handleNewsData">
                        <NewsList size="short" />
                    </Chip>
                    <Chip
                        id="195909"
                        type="recommend"
                        title="媒体合作列表"
                        groupName="关注凤凰"
                        content={coorList}
                        translate="handleNewsData">
                        <NewsList size="short" />
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
