import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../utils/rel';
import { cookie } from '@ifeng-fe/ui_base';

const wxIsclose = typeof document !== 'undefined' ? cookie.get('wxIsclose') : '';

class QrCode extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        isShow: wxIsclose === 'false',
    };

    componentDidMount() {
        const data = new Date();
        const wxCloseEnd = data.getTime();
        const wxCloseStart = cookie.get('wxCloseStart');
        const time = wxCloseEnd - wxCloseStart;

        if (time >= 60 * 60 * 1000) {
            cookie.set('wxIsclose', false);
            this.setState({
                isShow: true,
            });
        }
    }

    handleClick = () => {
        const data = new Date();

        cookie.set('wxIsclose', true);
        cookie.set('wxCloseStart', data.getTime());

        this.setState({
            isShow: false,
        });
    };

    /**
     * 渲染组件
     */
    render() {
        const { isShow } = this.state;

        const {
            content: {
                qrCode: { url, title, src, width, height },
            },
        } = this.props;

        const bottomAffix = isShow ? (
            <div id="qrCode" className={styles.qr_box}>
                {/* <span className={styles.close} onClick={this.handleClick}>
                        关闭
                    </span>
                    <a className={styles.qr} href={url} target="_blank" rel={rel}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.img}>
                            <img src={src} width={width} height={height} alt={title} title={title} />
                        </div>
                        <div className={styles.tip}>
                            <p>微信扫一扫</p>
                            <p>随时看热点</p>
                        </div>
                    </a> */}
                <a className={styles.qr} href={url} target="_blank" rel={rel}>
                    <div className={styles.img}>
                        <img src={src} width={width} height={height} alt={title} title={title} />
                    </div>
                </a>
            </div>
        ) : (
            <div />
        );

        return ReactDOM.createPortal(bottomAffix, document.body);
    }
}

export default errorBoundary(QrCode);
