import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import Promote from '../../../components/promote';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: {
                cultureBookTitle,
                cultureBookList0,
                shopInCultureBook,
                cultureBookList1,
                techListAd,
                techHardAd,
                promoteInEdu,
                bookRSAd,
                replaceShopConfig2
            },
        } = this.props;

        return (
            <div className={styles.left_box}>
                <Chip id="200140" type="struct" title="读书标题" groupName="读书" content={cultureBookTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="285676"
                    type="recommend"
                    title="读书列表1"
                    groupName="读书"
                    content={cultureBookList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                {replaceShopConfig2.isShow ?
                    <NoSSR>
                        <Ad content={bookRSAd} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="long" content={shopInCultureBook} sourceName="shopInCultureBook" />
                }
                <Chip
                    id="285677"
                    type="recommend"
                    title="读书列表2"
                    groupName="读书"
                    content={cultureBookList1}
                    translate="handleNewsData">
                    <NewsList size="long" ad={techListAd} />
                </Chip>
                <NoSSR>
                    <Ad content={techHardAd} styleName={styles.dbox} />
                </NoSSR>
                {promoteInEdu && (
                    <Chip id="230196" type="struct" title="铺底推广" groupName="读书" content={promoteInEdu}>
                        <Promote />
                    </Chip>
                )}
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
