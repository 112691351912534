import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import { rel } from '../../../../utils/rel';

class TitleImg extends React.PureComponent {
    static propTypes = {
        size: PropTypes.string,
        content: PropTypes.object,
    };

    render() {
        const {
            size,
            content: { thumbnail, url, title, pcNewsMark = [] },
        } = this.props;
        const isAd = pcNewsMark.indexOf(5) !== -1;

        return (
            <a className={styles.title_img} href={url} target="_blank" rel={rel}>
                <img className={styles.title_img_pic} src={formatImageWebpAuto(thumbnail, 175, 98)} />
                <span className={`${styles.title_img_info} ${size === 'long' ? styles.long_info : styles.short_info}`}>
                    <span>{title}</span>
                    {isAd && (
                        <img className={styles.mark} src="https://x0.ifengimg.com/feprod/c/2023_1_17/16_21_21/1.png" />
                    )}
                    {/* <span>{newsTime}</span> */}
                </span>
            </a>
        );
    }
}

export default errorBoundary(TitleImg);
