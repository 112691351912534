import { setlocal, getlocal } from './utils';

// 电商数据初始话 默认显示第一条
export const shopDataInit = (data = []) => {
    const result = data.map(item => {
        return item.regionA?.[0];
    });

    return result;
};

// 电商数据初始话 默认显示第一条
export const shopDataHandlePlan1 = (data = []) => {
    const result = data.map(item => {
        if (window?.allData?.isBeijing && item?.regionB?.length > 0 && item.regionB?.[0]?.title) {
            const regionB = window?.allData?.isShopLiveDaySencond && item?.regionB?.[1]?.title ? item.regionB[1] : item.regionB?.[0];

            return regionB;
        } else {
            const regionA = window?.allData?.isShopLiveDaySencond && item?.regionA?.[1]?.title ? item.regionA[1] : item.regionA?.[0];

            return regionA;
        }
    });

    return result;
};

export const shopDataHandlePlan2 = (data = [], key) => {
    const shopDataCollect = getlocal('shopDataCollect') || {};
    const result = data.map(item => {
        if (window?.allData?.isBeijing && item?.regionB?.length > 0 && item.regionB?.[0]?.title) {
            let regionB = {};

            if (item?.regionB?.[1]?.title && shopDataCollect?.[key]?.length > 0) {
                regionB = item.regionB.filter(item => !shopDataCollect[key].includes(item.url))?.[0];
                regionB = regionB || item?.regionB?.[0];
            } else {
                regionB = item?.regionB?.[0];
            }

            return regionB;
        } else {
            let regionA = {};

            if (item?.regionA?.[1]?.title && shopDataCollect?.[key]?.length > 0) {
                regionA = item.regionA.filter(item => !shopDataCollect[key].includes(item.url))?.[0];
                regionA = regionA || item?.regionA?.[0];
            } else {
                regionA = item?.regionA?.[0];
            }

            return regionA;
        }
    });

    shopDataCollect[key] = result.map(item => item.url);

    setlocal('shopDataCollect', shopDataCollect);

    return result;
};
