import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import FLiveSlide from './fLiveSlide';
import { getFLiveData } from '../../../../services/api';
import { handleFLiveSlideData } from '../../../../utils/utils';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        slideData: [],
        fLiveList: [],
    };

    async componentDidMount() {
        try {
            const data = await getFLiveData();
            const slideData = handleFLiveSlideData(data.slice(0, 15), 3);

            this.setState({
                slideData: [...slideData],
                fLiveList: [...data.slice(15)].slice(0, 5),
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { slideData, fLiveList } = this.state;
        const {
            content: { fLiveTitle, fLiveAd, fLiveListAd },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="170171" type="struct" title="风直播标题" groupName="风直播" content={fLiveTitle}>
                    <NewsTitle size="short" />
                </Chip>
                {slideData.length > 0 && <FLiveSlide ad={fLiveAd} slideData={slideData.slice(0, 15)} />}
                <NewsList size="short" content={fLiveList} ad={fLiveListAd} />
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
