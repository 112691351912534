import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../components/newsTitle';
import LeftNewsBox from './leftNewsBox';
import CenterNewsBox from './centerNewsBox';
import RightNewsBox from './rightNewsBox';

class NewsBox5 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { shopTitle2, leftData, centerData, rightData },
        } = this.props;

        return (
            <div className={styles.news_box}>
                <div className={styles.box_l}>
                    <Chip id="200150" type="struct" title="凰家尚品标题" groupName="凰家尚品" content={shopTitle2}>
                        <NewsTitle size="longer" />
                    </Chip>
                    <div className="clearfix">
                        <LeftNewsBox content={leftData} />
                        <CenterNewsBox content={centerData} />
                    </div>
                </div>
                <div className={styles.box_r}>
                    <div className="clearfix">
                        <RightNewsBox content={rightData} />
                    </div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(NewsBox5);
