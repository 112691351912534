import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';

class ListTitle extends React.PureComponent {
    static propTypes = {
        isBig: PropTypes.bool,
        content: PropTypes.array,
        isUnfold: PropTypes.bool,
    };

    render() {
        const { isBig, content, isUnfold } = this.props;

        /* eslint-disable no-confusing-arrow */
        return (
            <div className={`${styles.list_title_box} ${isUnfold ? styles.list_title_box_unfold : ''}`}>
                <h3 className={`${styles.list_title} ${isBig ? styles.big : styles.normal}`}>
                    {content.map((item, index) =>
                        item.children && item.children.length > 0 ? (
                            <React.Fragment key={index}>
                                <a
                                    className={styles.space}
                                    title={item.title}
                                    href={item.url}
                                    target="_blank"
                                    rel={rel}>
                                    {item.title}
                                </a>
                                {item.children.map((item, index) => (
                                    <a
                                        key={index}
                                        className={styles.space}
                                        href={item.url}
                                        title={item.title}
                                        target="_blank"
                                        rel={rel}>
                                        {item.title}
                                    </a>
                                ))}
                            </React.Fragment>
                        ) : (
                            <a key={index} href={item.url} title={item.title} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                        ),
                    )}
                </h3>
            </div>
        );
        /* eslint-enable no-confusing-arrow */
    }
}

export default errorBoundary(ListTitle);
