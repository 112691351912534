import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import Promote from '../../../components/promote';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: {
                foodTitle,
                foodList0,
                shopInFood0,
                foodList1,
                foodList2,
                shopGroup1,
                shopInFood1,
                foodAd,
                foodListAd,
                foodListRSAd,
                promoteInFood,
                foodRSAd1,
                foodRSAd2,
                foodRSAd3,
                foodRSAd4,
                replaceShopConfig
            },
        } = this.props;

        return (
            <div className={styles.left_box}>
                <Chip id="170175" type="struct" title="美食标题" groupName="美食" content={foodTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="195867"
                    type="recommend"
                    title="美食列表1"
                    groupName="美食"
                    content={foodList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                {replaceShopConfig.isShow ?
                    <NoSSR>
                        <Ad content={foodRSAd1} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="long" content={shopInFood0} sourceName="shopInFood0" />
                }
                <Chip
                    id="225750"
                    type="recommend"
                    title="美食列表2"
                    groupName="美食"
                    content={foodList1}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                {replaceShopConfig.isShow ?
                    <NoSSR>
                        <Ad content={foodRSAd2} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="long" content={shopGroup1} sourceName="shopGroup1" />
                }
                <Chip
                    id="285877"
                    type="recommend"
                    title="美食列表3"
                    groupName="美食"
                    content={foodList2}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                {replaceShopConfig.isShow ? 
                    <ShopList size="long" ad={foodListRSAd} content={shopInFood1} sourceName="shopInFood1" /> :
                    <ShopList size="long" ad={foodListAd} content={shopInFood1} sourceName="shopInFood1" />
                }
                <NoSSR>
                    <Ad content={foodAd} styleName={styles.dbox} />
                </NoSSR>
                {promoteInFood && (
                    <Chip id="200152" type="struct" title="铺底推广" groupName="美食" content={promoteInFood}>
                        <Promote />
                    </Chip>
                )}
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
