import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Slides from '@ifeng-fe/ui_pc_slides';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';

class PhtvSlide extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    sliderTmpl = item => {
        return (
            <div className={styles.big_pic}>
                <a href={item.url} className={styles.pic_img} target="_blank" data-nomask>
                    <img src={formatImageWebpAuto(item.thumbnail, 360, 203)} className={styles.trans} />
                </a>
                <div className={styles.text} />
                <p className={styles.title}>
                    <a href={item.url} target="_blank" data-innermask>
                        {item.title}
                    </a>
                </p>
            </div>
        );
    };

    render() {
        const { content } = this.props;
        const config = {
            dots: true,
            dotsAction: 'hover',
            autoplayInterval: 3000,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl: this.sliderTmpl,
        };

        return (
            <div className={`${styles.slide_box}`}>
                <NoSSR>
                    <Slides content={content} config={config} />
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(PhtvSlide);
