// 一维数组转二维数组
export const oneChangeTwoDimensional = (arr, num) => {
    const result = [];

    for (let i = 0, len = arr.length; i < len; i += num) {
        const item = arr.slice(i, i + num);

        result.push(item);
    }

    return result;
};

// 处理风直播轮播图数据
export const handleFLiveSlideData = (data, num) => {
    const result = [];

    for (let i = 0; i < Math.ceil(data.length); i += num) {
        const item = {
            mainData: data[i],
            subData: data.slice(i + 1, i + 3),
        };

        result.push(item);
    }

    return result;
};

// 判断 IE 版本
export const isIE = ver => {
    if (typeof document !== 'undefined') {
        const b = document.createElement('b');

        b.innerHTML = `<!--[if IE ${ver}]><i></i><![endif]-->`;

        return b.getElementsByTagName('i').length === 1;
    } else {
        return false;
    }
};

// 判断 IE 版本是否低于 9
export const isIE78 = () => {
    let isIE78 = false;

    /* eslint-disable */
    if (typeof allData !== 'undefined' && allData.IE78) {
        isIE78 = true;
    }
    /* eslint-enable */

    return isIE78;
};

const isfastmode = () => {
    return (() => {
        let isfastmode = 1;

        try {
            localStorage.setItem('checkfastmode', 1);
            isfastmode = 0;
        } catch (e) {
            console.error(e);
        }

        return isfastmode;
    })();
};

export const setlocal = (key, value) => {
    if (isfastmode()) return '';
    localStorage.setItem(key, JSON.stringify(value));
};

export const getlocal = key => {
    if (isfastmode()) return '';

    return JSON.parse(localStorage.getItem(key));
};
