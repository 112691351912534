import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import NewsBox from '../../../components/newsBox';
import NoSSR from 'react-no-ssr';
import Ad from '@ifeng-fe/ui_pc_ad';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { entTitle, entListTitle, entList0, shopInEnt, entList1, entNewsBox, entRSAd, replaceShopConfig2 },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="230186" type="struct" title="娱乐标题" groupName="娱乐" content={entTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="195910"
                    type="recommend"
                    title="娱乐头条"
                    groupName="娱乐"
                    content={entListTitle}
                    translate="handleNewsData">
                    <NewsList hasTitle size="long" />
                </Chip>
                <Chip
                    id="195864"
                    type="recommend"
                    title="娱乐列表1"
                    groupName="娱乐"
                    content={entList0}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                {replaceShopConfig2.isShow ?
                    <NoSSR>
                        <Ad content={entRSAd} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="long" content={shopInEnt} sourceName="shopInEnt" />
                }
                <Chip
                    id="225748"
                    type="recommend"
                    title="娱乐列表2"
                    groupName="娱乐"
                    content={entList1}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                <NoSSR>
                    <Chip
                        id="225749"
                        type="recommend"
                        title="娱乐新闻盒"
                        groupName="娱乐"
                        content={entNewsBox}
                        translate="handleNewsData">
                        <NewsBox />
                    </Chip>
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
