import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import Promote from '../../../components/promote';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: {
                stockTitle,
                stockList0,
                shopInFinance,
                financeList1,
                financeListAd,
                financeHardAd,
                promoteInFinance,
                financeRSAd,
                replaceShopConfig2
            },
        } = this.props;

        return (
            <div className={styles.left_box}>
                <Chip id="170172" type="struct" title="股票标题" groupName="股票" content={stockTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="225738"
                    type="recommend"
                    title="股票列表1"
                    groupName="股票"
                    content={stockList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                {replaceShopConfig2.isShow ?
                    <NoSSR>
                        <Ad content={financeRSAd} styleName={styles.adBox} />
                    </NoSSR> :
                    <ShopList size="long" content={shopInFinance} sourceName="shopInFinance" />
                }
                <Chip
                    id="225741"
                    type="recommend"
                    title="股票列表2"
                    groupName="股票"
                    content={financeList1}
                    translate="handleNewsData">
                    <NewsList size="long" ad={financeListAd} />
                </Chip>
                <NoSSR>
                    <Ad content={financeHardAd} styleName={styles.dbox} />
                </NoSSR>
                {promoteInFinance && (
                    <Chip id="230192" type="struct" title="铺底推广" groupName="股票" content={promoteInFinance}>
                        <Promote />
                    </Chip>
                )}
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
