import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import LeftNewsBox from './leftNewsBox';
import CenterNewsBox from './centerNewsBox';
import RightNewsBox from './rightNewsBox';

class NewsBox1 extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { leftData, centerData, rightData, stockAndQuotationHardAd },
        } = this.props;

        return (
            <div className={styles.news_box}>
                <LeftNewsBox content={leftData} />
                <div className={styles.box_r}>
                    <div className="clearfix">
                        <CenterNewsBox content={centerData} />
                        <RightNewsBox content={rightData} />
                    </div>
                    <NoSSR>
                        <Ad content={stockAndQuotationHardAd} styleName={styles.d_box} />
                    </NoSSR>
                </div>
            </div>
        );
    }
}

export default errorBoundary(NewsBox1);
