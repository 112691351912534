import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import { rel } from '../../../utils/rel';

class NewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.news_box}>
                {content.map((item, index) => (
                    <a key={index} className={styles.news} href={item.url} target="_blank" rel={rel}>
                        <img src={formatImageWebpAuto(item.thumbnail, 230, 130)} alt="" />
                        <p className={styles.text}>
                            <span>{item.title}</span>
                        </p>
                    </a>
                ))}
            </div>
        );
    }
}

export default errorBoundary(NewsBox);
