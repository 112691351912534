import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../utils/rel';

class Jubao extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    state = {
        current: 0,
    };

    componentDidMount() {
        const { content } = this.props;
        let a = 0;

        this.timer = setInterval(() => {
            this.setState({ current: a });

            if (a === content.length - 1) {
                a = 0;
            } else {
                a += 1;
            }
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    /**
     * 渲染组件
     */
    render() {
        const { current } = this.state;
        const { content } = this.props;

        return (
            <div className={styles.jubao}>
                {content.map((item, index) => (
                    <a
                        key={index}
                        className={`${styles.wljd} ${current === index ? '' : styles.hide}`}
                        href={item.url}
                        target="_blank"
                        rel={rel}>
                        <img src={item.src} alt="" />
                    </a>
                ))}
            </div>
        );
    }
}

export default errorBoundary(Jubao);
