import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../../components/newsTitle';
import NewsList from '../../../../components/newsList';
import NewsBox from '../../../../components/newsBox';
import NoSSR from 'react-no-ssr';

class FictionExtra extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { fictionAreaList, fictionAreaBox, fictionAreaTitle },
        } = this.props;

        return (
            <Fragment>
                <Chip id="260335" type="struct" title="其它地区小说标题" groupName="小说" content={fictionAreaTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="285883"
                    type="recommend"
                    title="其他地区小说列表"
                    groupName="小说"
                    content={fictionAreaList}
                    translate="handleNewsData">
                    <NewsList hasTitle size="long" />
                </Chip>
                <NoSSR>
                    <Chip
                        id="225819"
                        type="recommend"
                        title="其他地区小说新闻盒"
                        groupName="小说"
                        content={fictionAreaBox}
                        translate="handleNewsBoxData">
                        <NewsBox />
                    </Chip>
                </NoSSR>
            </Fragment>
        );
    }
}

export default errorBoundary(FictionExtra);
