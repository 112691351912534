import React from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import Chip from 'Chip';
import { cookie } from '@ifeng-fe/ui_base';
import { getLastReadData } from '@src/pc/services/api';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import Promote from '../../../components/promote';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        musicList: this.props.content.musicList
    }

    lastReadHandle = async () => {
        const { musicList } = this.state;
        const { musicTitle } = this.props.content;
        const sid = cookie.get('sid');

        if (sid && musicTitle?.[0]?.lastReadSwitch) {
            try {
                const data = await getLastReadData(sid);

                if (data?.title) {
                    this.setState({
                        musicList: [data, ...musicList.slice(0, musicList.length - 1)]
                    });
                }
            } catch (error) {
                console.error('最近阅读接口异常');
            }
        }
    }

    componentDidMount() {
        this.lastReadHandle();
    }

    render() {
        const {
            content: { musicTitle, musicAd, promoteInMusic },
        } = this.props;
        const { musicList } = this.state;

        return (
            <div className={styles.left_box}>
                <Chip id="200148" type="struct" title="有声音乐标题" groupName="有声音乐" content={musicTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="255878"
                    type="recommend"
                    title="有声音乐列表"
                    groupName="有声音乐"
                    content={musicList}
                    translate="handleNewsData">
                    <NewsList hasTitle size="long" />
                </Chip>
                <NoSSR>
                    <Ad content={musicAd} styleName={styles.dbox} />
                </NoSSR>
                {promoteInMusic && (
                    <Chip id="260813" type="struct" title="铺底推广" groupName="有声音乐" content={promoteInMusic}>
                        <Promote />
                    </Chip>
                )}
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
