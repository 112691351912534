import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { historyTitle, historyList, guoxueList, digitalListAd },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="230182" type="struct" title="国学标题" groupName="国学" content={historyTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="285675"
                    type="recommend"
                    title="国学列表"
                    groupName="国学"
                    content={guoxueList}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="short" ad={digitalListAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
