import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './index.css';
import { addEventListener } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../utils/rel';

class AddRed extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        isIpad: PropTypes.bool,
        turnRed: PropTypes.bool,
        handleCloseTurnRed: PropTypes.func,
    };

    state = {
        isBig: true,
    };

    componentDidMount() {
        this.unHandleResize = addEventListener(window, 'resize', this.handleResize);

        if (typeof window !== 'undefined' && typeof document !== 'undefined' && window.__IFENG_ENV__?.maxDocWidth) {
            const width = document.documentElement.clientWidth;

            this.setState({
                isBig: width > window.__IFENG_ENV__.maxDocWidth,
            });
        }
    }

    componentWillUnmount() {
        this.unHandleResize();
    }

    handleResize = () => {
        if (typeof window !== 'undefined' && typeof document !== 'undefined' && window.__IFENG_ENV__?.maxDocWidth) {
            const width = document.documentElement.clientWidth;

            this.setState({
                isBig: width > window.__IFENG_ENV__.maxDocWidth,
            });
        }
    };

    handleClick = () => {
        const { handleCloseTurnRed } = this.props;

        handleCloseTurnRed();
    };

    render() {
        const { isBig } = this.state;
        const {
            content: { src, srcHeight, smallSrc, smallSrcHeight, ipadSrc, ipadSrcHeight, url, canClose, closeImg },
            isIpad,
            turnRed,
        } = this.props;

        const body = document.querySelector('body');

        let bgImg = smallSrc;
        let bgImgHeight = smallSrcHeight;

        if (isBig) {
            bgImg = src;
            bgImgHeight = srcHeight;
        }

        if (isIpad) {
            bgImg = ipadSrc;
            bgImgHeight = ipadSrcHeight;
        }

        if (turnRed && !isIpad) {
            body.style.backgroundColor = '#fff';
            body.style.backgroundImage = `url(${bgImg})`;
            body.style.backgroundPosition = 'center 0px';
            body.style.backgroundRepeat = 'no-repeat';
        } else {
            body.style.background = 'none';
        }

        return turnRed ? (
            <div className={styles.red_cog}>
                <div
                    className={styles.top_img}
                    style={
                        isIpad
                            ? {
                                  background: `url(${bgImg}) no-repeat`,
                                  backgroundSize: 'cover',
                                  height: `${bgImgHeight}px`,
                              }
                            : { height: `${bgImgHeight}px` }
                    }>
                    {canClose ? (
                        <div
                            className={styles.closed}
                            style={{ background: `url(${closeImg}) no-repeat` }}
                            onClick={this.handleClick}
                        />
                    ) : (
                        ''
                    )}
                    <a className={styles.a_link} href={url} target="_blank" rel={rel} />
                </div>
            </div>
        ) : (
            <div />
        );
    }
}

export default errorBoundary(AddRed);
