import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import NoSSR from 'react-no-ssr';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ShopList from '../../../components/shopList';
import ShopBox from '../../../components/shopBox';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { shopList4, shopList5 },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip
                    id="260689"
                    type="struct"
                    title="凰家尚品列表3"
                    groupName="凰家尚品"
                    content={shopList4}
                    translate="handleShopStructData">
                    <ShopList hasTitle size="long" sourceName="shopList4" />
                </Chip>
                <NoSSR>
                    <Chip
                        id="260690"
                        type="struct"
                        title="凰家尚品列表4"
                        groupName="凰家尚品"
                        content={shopList5}
                        translate="handleShopStructData">
                        <ShopBox sourceName="shopList5" />
                    </Chip>
                </NoSSR>
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
