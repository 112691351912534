/* eslint-disable no-confusing-arrow */
const { formatImage, formatUrl } = require('@ifeng-fe/public_method');

const handleChildrenData = data => {
    try {
        if (data) {
            return data.map(item => ({
                url: item.url,
                title: item.title,
                pcNewsMark: (item.extentData && item.extentData.pcNewsMark) || item.pcNewsMark,
            }));
        }
    } catch (error) {
        console.error(error);
    }

    return null;
};

const handleThumbnail = (data, w, h) => {
    try {
        const imageUrl = data?.thumbnails?.image[0]?.url || data.thumbnail;

        if (imageUrl) {
            return formatImage(imageUrl, w, h);
        }
    } catch (error) {
        console.error(error);
    }

    return '';
};

const shopListDataHandle = (listItem, hasAdMark) => {
    const listItemPcNewsMark = [];
    const isAddBold = listItem?.['isAddBold'] ?? false;
    const hasAdIcon = listItem?.hasAdIcon ?? true;

    if (isAddBold === true) {
        listItemPcNewsMark.push(1);
    }

    if (hasAdIcon === true && hasAdMark === true) {
        listItemPcNewsMark.push(5);
    }

    const children =
        listItem?.['title2'] && listItem?.['url2']
            ? [
                  {
                      title: listItem['title2'],
                      url: listItem['url2'],
                      pcNewsMark: listItemPcNewsMark,
                  },
              ]
            : null;

    return {
        title: listItem?.['title1'] || '',
        url: listItem?.['url1'] || '',
        thumbnail: listItem?.['thumbnail'] ?? '',
        pcNewsMark: listItemPcNewsMark,
        children,
    };
};

module.exports = {
    jsonParse: data => {
        try {
            return JSON.parse(data);
        } catch (error) {
            console.error(error);
        }

        return data;
    },
    // 电商结构化碎片数据处理
    handleShopStructData: (data = {}, hasAdMark = true) => {
        // 遍历data下的对象
        const dataList = [];

        for (const key in data) {
            const dataListItem = {};

            // 在遍历data下的对象的属性
            if (data?.[key]) {
                for (const key2 in data[key]) {
                    // 在遍历data下的对象的属性中list数组
                    const dataItem = data?.[key]?.[key2];
                    let dataListSort = [];

                    dataListItem[key2] = [];
                    // list下数据是否存在和符合时间段条件
                    if (dataItem?.['list']?.length > 0) {
                        for (let i = 0; i < dataItem['list'].length; i++) {
                            // 获取当前时间并与list数组中startTime和endTime的进行比较，过滤不符合时间段的数据
                            const listItem = dataItem['list'][i];
                            const nowTime = new Date().getTime();
                            const startTime = new Date(listItem?.['startTime']).getTime();
                            const endTime = new Date(listItem?.['endTime']).getTime();

                            // 如果当前时间在startTime和endTime之间，就将该条数据返回，否则就显示title1、url1、title2、url2的打底数据
                            if (nowTime > startTime && nowTime < endTime) {
                                dataListItem[key2].push(shopListDataHandle(listItem, hasAdMark));
                            }
                        }

                        // 按照"开始时间"距离"当前时间"多少进行降序，获取获取第一条
                        dataListSort = dataItem['list'].sort((a, b) => {
                            const aTime = new Date(a.startTime).getTime();
                            const bTime = new Date(b.startTime).getTime();

                            return bTime - aTime;
                        });
                        
                        // 不符合时间段条件的条件获取list下第一条
                        if (dataListItem?.[key2]?.length === 0 && dataListSort?.[0]) {
                            dataListItem[key2].push(shopListDataHandle(dataListSort[0], hasAdMark));
                            if (
                                dataListSort?.[0]?.startTime === dataListSort?.[1]?.startTime &&
                                dataListSort?.[0]?.endTime === dataListSort?.[1]?.endTime
                            ) {
                                dataListItem[key2].push(shopListDataHandle(dataListSort[1], hasAdMark));
                            }
                        }
                    }

                    // list下数据不存在或者不符合时间段条件
                    if (dataListItem?.[key2]?.length === 0) {
                        dataListItem[key2].push(shopListDataHandle(dataItem, hasAdMark));
                    }

                    // 只获取最近的两条数据
                    dataListItem[key2] = dataListItem?.[key2]?.slice?.(0, 2) || [];
                }

                dataList.push(dataListItem);
            }
        }

        return dataList;
    },
    // 处理要闻焦点图
    handleNewsSlideData: (data, name) => {
        try {
            return data.map(item => ({
                thumbnail:
                    name && name.includes('newsSlidePhtv')
                        ? handleThumbnail(item, 360, 203)
                        : handleThumbnail(item, 480, 270),
                url: item.url,
                title: item.title,
                pcNewsMark: item.pcNewsMark,
            }));
        } catch (error) {
            console.error(error);
        }

        return data;
    },
    // 处理文字链列表
    handleNewsTopData: data => {
        try {
            return data.slice(0, 3).map(item => ({
                url: item.url,
                title: item.title,
                marquee: item.marquee,
                pcNewsMark: item.pcNewsMark,
                children: handleChildrenData(item.children),
            }));
        } catch (error) {
            console.error(error);
        }

        return data.slice(0, 3);
    },
    handleNewsListData: data => {
        try {
            return data.slice(0, 15).map(item => ({
                url: item.url,
                title: item.title,
                marquee: item.marquee,
                pcNewsMark: item.pcNewsMark,
                children: handleChildrenData(item.children),
            }));
        } catch (error) {
            console.error(error);
        }

        return data.slice(0, 15);
    },
    handleGovListData: data => {
        try {
            return data.slice(0, 2).map(item => ({
                url: item.url,
                title: item.title,
                pcNewsMark: item.pcNewsMark,
                children: handleChildrenData(item.children),
            }));
        } catch (error) {
            console.error(error);
        }

        return data.slice(0, 2);
    },
    // 处理文字链列表
    handleNewsData: data => {
        try {
            return data.map((item, index) =>
                index === 0
                    ? {
                          url: item.url,
                          title: item.title,
                          pcNewsMark: item.pcNewsMark,
                          newsTime: item.newsTime,
                          thumbnail: handleThumbnail(item, 175, 98),
                          children: handleChildrenData(item.children),
                      }
                    : {
                          url: item.url,
                          title: item.title,
                          pcNewsMark: item.pcNewsMark,
                          children: handleChildrenData(item.children),
                      },
            );
        } catch (error) {
            console.error(error);
        }

        return data;
    },
    // 处理视频盒数据
    handleVideoBoxData: data => {
        try {
            return data.map(item => ({
                thumbnail: handleThumbnail(item, 175, 98),
                url: item.url,
                title: item.title,
            }));
        } catch (error) {
            console.error(error);
        }

        return data;
    },
    // 处理新闻盒数据
    handleNewsBoxData: data => {
        try {
            return data.map(item => ({
                thumbnail: handleThumbnail(item, 230, 130),
                url: item.url,
                title: item.title,
            }));
        } catch (error) {
            console.error(error);
        }

        return data;
    },
};
