import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';

class ModuleTitle extends React.PureComponent {
    static propTypes = {
        size: PropTypes.string,
        type: PropTypes.string,
        current: PropTypes.number,
        content: PropTypes.array,
        children: PropTypes.array,
    };

    state = {
        current: this.props.current,
    };

    handleMouseOver = e => {
        const index = Number(e.currentTarget.getAttribute('index'));

        if (index !== this.state.current) {
            this.setState({
                current: index,
            });
        }
    };

    render() {
        const { current } = this.state;
        const { size, type, content } = this.props;

        const titleArr = content.filter(item => !item.moreImg);
        const moreArr = content.filter(item => item.moreImg);

        let styleName = '';

        if (size === 'longer') {
            styleName = styles.longer_title_box;
        } else if (size === 'long') {
            styleName = styles.long_title_box;
        } else if (size === 'middle') {
            styleName = styles.middle_title_box;
        } else if (size === 'short') {
            styleName = styles.short_title_box;
        }

        return (
            <Fragment>
                <div className={`${styles.title_box} ${styleName}`}>
                    {type === 'tab' ? (
                        <Chip id="200153" type="struct" title="要闻标题" groupName="要闻区">
                            <div className={styles.title}>
                                {titleArr.map((item, index) => (
                                    <Fragment key={index}>
                                        {index === 0 ? '' : <span className={styles.dr} />}
                                        <a
                                            className={`${current === index ? '' : styles.no_active} ${
                                                index === 0 ? '' : styles.tab
                                            }`}
                                            href={item.url}
                                            target="_blank"
                                            index={index}
                                            onMouseOver={this.handleMouseOver}>
                                            {item.title}
                                        </a>
                                    </Fragment>
                                ))}
                            </div>
                        </Chip>
                    ) : (
                        <div className={styles.title}>
                            {titleArr.map((item, index) => (
                                <Fragment key={index}>
                                    {index === 0 ? '' : <span>·</span>}
                                    <a href={item.url} target="_blank">
                                        {item.title}
                                    </a>
                                    <img
                                        src="https://x0.ifengimg.com/ucms/2021_51/BF272305A6C6DA0B63CECBF0460A5B4B001345E3_size1_w22_h24.png"
                                        className={styles.phtvIcon}
                                    />
                                </Fragment>
                            ))}
                        </div>
                    )}
                    {moreArr.length > 0 && (
                        <a
                            className={styles.more}
                            style={{ bottom: `${moreArr[0].moreBottom || 0}px` }}
                            href={moreArr[0].moreUrl}
                            target="_blank">
                            <img
                                src={moreArr[0].moreImg}
                                width={moreArr[0].moreImgWidth}
                                height={moreArr[0].moreImgHeight}
                            />
                        </a>
                    )}
                </div>
                {type === 'tab' &&
                    React.Children.map(this.props.children, (item, index) =>
                        React.cloneElement(item, { active: current === index }),
                    )}
            </Fragment>
        );
    }
}

export default errorBoundary(ModuleTitle);
