import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Nav from './nav/';
import SubNav from './subNav/';

class Navigation extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        turnRed: PropTypes.bool,
    };

    /**
     * 渲染组件
     */
    render() {
        const {
            content: { nav, subNav, subNavArea },
            turnRed,
        } = this.props;

        return (
            <React.Fragment>
                <div className={`${turnRed ? styles.bgnone : styles.nav}`}>
                    <Chip id="50056" type="struct" title="主导航" groupName="导航栏" content={nav}>
                        <Nav />
                    </Chip>
                </div>
                <NoSSR onSSR={<div className={styles.ssr_nav} />}>
                    <Chip id="50057" type="struct" title="副导航" groupName="导航栏" content={subNav}>
                        <SubNav subNavArea={subNavArea} />
                    </Chip>
                </NoSSR>
            </React.Fragment>
        );
    }
}

export default errorBoundary(Navigation);
