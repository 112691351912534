import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { newCarGuideTitle, newCarGuideList1, newCarGuideListAd },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="230185" type="struct" title="新车导购标题" groupName="新车导购" content={newCarGuideTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="288481"
                    type="recommend"
                    title="新车导购列表"
                    groupName="新车导购"
                    content={newCarGuideList1}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="short" ad={newCarGuideListAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
