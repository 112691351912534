import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import ShopList from '../../../components/shopList';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { shopList3 },
        } = this.props;

        return (
            <div className={styles.left_box}>
                <Chip
                    id="260688"
                    type="struct"
                    title="凰家尚品列表2"
                    groupName="凰家尚品"
                    content={shopList3}
                    translate="handleShopStructData">
                    <ShopList hasTitleImg size="long" sourceName="shopList3" />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
